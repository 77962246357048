/* You can add global styles to this file, and also import other style files */
@import "node_modules/ol/ol.css";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

:root
{ --ropon-blue: #1c4587;
  --navbar-background: #7fa5b2;
  --badge-domain: #38761d;
  --badge-discipline: #134f5c;
  --badge-asset-type: var(--ropon-blue);
  --ropon-normal: 300;
  --ropon-bold: 700;
  --ropon-map-size: 270px }

body
{ font-family: Poppins,system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-weight: var(--ropon-normal);
  font-style: normal;
  -webkit-font-smoothing: antialiased }
